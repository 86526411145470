html,
body,
#root {
  margin: 0;
  display: flex;
  min-height: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  background-color: #F4F1EB;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::placeholder {
  color: white;
}

.ant-select-selection, .ant-time-picker-input {
  background-color: rgb(82, 103, 204)!important;
  border: none!important;
  color: white!important;
  font-weight: bold;
  font-size: 16px;
  height: 38px!important;
  border-radius: 19px!important;
}

.ant-select-selection__rendered {
  line-height: 38px!important;
  margin-left: 20px!important;
}

.anticon.anticon-down.ant-select-arrow-icon {
  color: white!important;
}

.ant-list-empty-text {
  display: none;
}

form input[type=checkbox], .ant-checkbox,  .ant-checkbox-input, .ant-checkbox-inner, .ant-checkbox-wrapper {
  width: 32px!important;
  height: 32px!important;
}

.ant-checkbox-inner {
  border-radius: 16px!important;
  background-color: rgb(82, 103, 204)!important;
    border-color:rgb(82, 103, 204)!important;
}
.ant-checkbox-inner::after {
  left: 36%!important;
}

.ant-checkbox-group-item.ant-checkbox-wrapper {
  white-space: nowrap!important;
  margin-right: 40px!important;
}

.ant-checkbox-group-item.ant-checkbox-wrapper p {
  margin-left: 0!important;
}