.ant-calendar-picker-input.ant-input {
  text-align: center;
  background-color: rgb(82, 103, 204);
  color: white;
  font-size: 16px;
  font-family: Space Grotesk;
  height: 40px;
  border-radius: 20px; 
  cursor: pointer;
  border: none;
}

.ant-tabs-ink-bar {
  background-color: rgb(82, 103, 204);
  
}
.ant-calendar-picker-input.ant-input:hover {
  border: none;
}
.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab {
  font-weight: bold;
  font-size: 16px;
  color: rgb(82, 103, 204);
  background: none;
  padding: 6px 16px;
}


/*
.ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 3px solid blue;
}
*/

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #1e1a4d;
}
